// noinspection JSUnusedLocalSymbols,DuplicatedCode

import { ComponentType, SvelteComponent } from "svelte";

// types ***********************************************************************

interface SvelteComponentRegistry {
  name: string | null;
  component: ComponentType;
  props?: {} | null;
}

const svelteDOM = {
  registerComponents(
    _registry: SvelteComponentRegistry[],
    _debug: boolean = false,
  ) {},
};

// helpers *********************************************************************

const domReady = new Promise<void>((resolve) => {
  document.addEventListener("DOMContentLoaded", () => {
    resolve();
  });
});

const markLoadedComponent = (
  DOMElement: HTMLElement,
  component: ComponentType,
  newComponent: SvelteComponent,
  tsProps: {},
  windowProps: {},
  DOMProps: {},
  debug: boolean = false,
) => {
  let name = DOMElement.dataset.svelte;
  DOMElement.removeAttribute("data-props");
  DOMElement.dataset.loaded = "true";

  let hydratedBy = [];
  if (Object.keys(tsProps).length > 0) {
    hydratedBy.push("ts");
  }
  if (Object.keys(windowProps).length > 0) {
    hydratedBy.push("window");
  }
  if (Object.keys(DOMProps).length > 0) {
    hydratedBy.push("inline");
  }
  DOMElement.dataset.hydratedBy = hydratedBy.join(" ");

  if (!debug) {
    return;
  }
  let debugData = {
    _svelteComponent: newComponent,
    _importedComponent: component,
    props: {
      tsProps,
      windowProps,
      DOMProps,
    },
  };

  console.info("%c" + `loaded svelte component '${name}'`, "color: #8FBE79");
  console.info(debugData);
};

const markUnloadedComponents = () => {
  const unloadedComponents = document.querySelectorAll(
    "[data-svelte]:not([data-loaded])",
  );
  unloadedComponents.forEach((DOMElement: HTMLElement) => {
    const componentName = DOMElement.dataset.svelte;
    console.error(
      `error loading svelte component '${componentName}'. Did you forget to register it in your entry point?`,
    );
    DOMElement.removeAttribute("data-props");
    DOMElement.dataset.loaded = "false";
  });
};

// library *********************************************************************

svelteDOM.registerComponents = async (
  registry: SvelteComponentRegistry[],
  debug: boolean = false,
) => {
  await domReady;

  registry.forEach((entry) => {
    const DOMElements: NodeListOf<HTMLElement> = document.querySelectorAll(
      `[data-svelte="${entry.name}"]`,
    );

    if (!DOMElements || DOMElements.length === 0) {
      // couldn't find any elements with the given selector
      return;
    }

    DOMElements.forEach((DOMElement) => {
      let tsProps = entry.props || {};
      let windowProps = {};
      if (
        window["svelte"] !== undefined &&
        window["svelte"][entry.name] !== undefined
      ) {
        windowProps = window["svelte"][entry.name];
      }
      let DOMProps = DOMElement.dataset.props
        ? JSON.parse(DOMElement.dataset.props)
        : {};
      const combinedProps = Object.assign({}, tsProps, windowProps, DOMProps);

      const newComponent = new entry.component({
        target: DOMElement,
        props: combinedProps,
      });

      markLoadedComponent(
        DOMElement,
        entry.component,
        newComponent,
        tsProps,
        windowProps,
        DOMProps,
        debug,
      );
    });
  });

  markUnloadedComponents();
};

export { svelteDOM };
